import React, { useEffect, useState } from "react"
import { Button, Textarea, VisualSizesEnum } from "@frontapp/ui-kit"
import { ApplicationMessageId } from "@frontapp/plugin-sdk"
import GeneratePrompt from "./GeneratePrompt";

interface Props {
    onClick: (prompt: string) => void
    messageId: ApplicationMessageId | undefined
}

function PromptInput(props: Props): React.ReactElement {
    const [prompt, setPrompt] = useState("")

    useEffect(() => {
        if (props.messageId) {
            setPrompt("")
        }
    }, [props.messageId])

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <div style={{marginTop: 8, width: "100%"}}>
                <Textarea
                    placeholder="Custom prompt"
                    onChange={setPrompt}
                    value={prompt}
                />
            </div>
            <div style={{marginTop: 8}}>
                <Button
                    size={VisualSizesEnum.SMALL}
                    onClick={() => props.onClick(prompt)}
                >
                    Generate
                </Button>
            </div>
            <GeneratePrompt/>
        </div>
    )
}

export default PromptInput
