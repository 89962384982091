export async function generateResponse(
    prompt: string,
    apiToken: string
): Promise<string> {
    console.log(prompt)
    const response = await fetch("https://chat-gpt.meetings.bio/", {
        body: JSON.stringify({ prompt: prompt }),
        method: "POST",
        headers: {
            Authorization: `Token ${apiToken}`,
            "Content-Type": "application/json",
        },
    })

    return (await response.json())["answer"]
}

export async function openApiGenerateResponse(
    prompt: string,
    apiKey: string
): Promise<string> {
    const url = "https://api.openai.com/v1/chat/completions"
    const response = await fetch(url, {
        body: JSON.stringify({
            model: "gpt-4o",
            messages: [{ role: "user", content: prompt }],
        }),
        method: "POST",
        headers: {
            Authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json",
        },
    })

    return (await response.json()).choices[0].message.content.trim()
}
