import React, { useState } from "react"
import { Button, Textarea, VisualSizesEnum } from "@frontapp/ui-kit"
import { SingleConversationContext } from "@frontapp/ui-bridge/dist/internal/contextTypesV2"
import { useFrontContext } from "./providers/frontContext"

function GeneratePrompt(): React.ReactElement {
    const context: SingleConversationContext = useFrontContext()
    const [prompt, setPrompt] = useState("")

    const generatePrompt = () => {
        context.listMessages().then((response) => {
            let newPrompt = ""

            response.results.forEach((message) => {
                const parser = new DOMParser()
                const body = message.content?.body || "";
                const doc = parser.parseFromString(body, "text/html")
                newPrompt += `
                <EMAIL>
                <EVENT_TYPE>email (${message.status})</EVENT_TYPE>
                <TIME>${message.date}</TIME>
                <SUBJECT>${message.subject}</SUBJECT>
                <SENDER>${message.from.handle}</SENDER>
                <RECIPIENTS>${message.to
                    .map((r) => r.handle)
                    .join(", ")}</RECIPIENTS>
                <BODY>
                ${doc.body.textContent}
                </BODY>
                </EMAIL>`
            })

            setPrompt(newPrompt)
        })
    }

    return (
        <>
            <div style={{ marginTop: 8 }}>
                <Button size={VisualSizesEnum.SMALL} onClick={generatePrompt}>
                    Generate Prompt
                </Button>
            </div>
            <div style={{ marginTop: 8, width: "100%" }}>
                <Textarea
                    placeholder="Generated prompt"
                    onChange={setPrompt}
                    value={prompt}
                />
            </div>
        </>
    )
}

export default GeneratePrompt
