export function generatePrompt(
    promptInstructions: string,
    latestEmailBody: string
): string {
    return `Following is the last response in a professional conversation. ${promptInstructions} 
    Generate appropriate response body only, do not include mock signature.\n\n==========\n${latestEmailBody}`
}

export interface Prompt {
    prompt: string
    displayName: string
}

export const prompts: { [key: string]: Prompt[] } = {
    tag_21n2ru:
        // Some
        [
            {
                prompt: "Reply with thanking for the email and question, and let the email sender know that we will get back with an answer as soon as possible",
                displayName: "We'll answer ASAP",
            },
        ],
    tag_21n2ve:
        // FWD
        [
            {
                prompt: "Reply with thanking for the response and for forwarding the email to their colleagues. Indicate that the colleagues can reach out directly for any questions or comments",
                displayName: "We're available if interested",
            },
        ],
    tag_21n2mi:
        // Fwd: CC
        [
            {
                prompt: "Reply with thanking for the response and for including their colleague in the email correspondence. Greet the person included in the email. Indicate a willingnes to meet do discuss matter further. Ask for their availability",
                displayName: "Greet new recipient and ask for availability",
            },
        ],
    tag_21n2tm:
        // Fwd: To
        [
            {
                prompt: "Reply with thanking for the response and for suggesting whom among their colleagues we should contact. Indicate you will reach out in a separate email",
                displayName: "Thank you. We'll contact colleague",
            },
        ],
    tag_21n1qi:
        // Negative
        [
            {
                prompt: "Reply with thanking for the response and very politely ask if the email sender can disclose why the service is not a good fit for their company. Express hope to collaborate on some other occassion or projects",
                displayName: "Why is it not good fit?",
            },
        ],
    tag_2ebvey:
        // Not now - archived
        [
            {
                prompt: "Reply with thanking for the response and indicate we will reach out again in a few months. Also mention that if they have any questions, they should feel free to reach out at any time.",
                displayName: "We'll follow up in few months",
            },
        ],
    tag_2ebvgq:
        // Not now - assigned
        [
            {
                prompt: "Reply with thanking for the response and indicate we will reach out again in the months indicated in the email response. Also mention that if they have any questions, they should feel free to reach out at any time.",
                displayName: "",
            },
        ],
    tag_21n1oq:
        // Positive
        [
            {
                prompt: "Reply with thanking for the response and interest to meet. Suggest availability and ask them to offer theirs. Use an exciting tone and mention we are looking forward to speaking further.",
                displayName: "Ask for availability",
            },
        ],
    tag_21n2kq:
        // Materials
        [
            {
                prompt: "Reply with thanking for the response and interest to learn more. Indicate that the email contains an attachment with further information and let them know that we look forward to their feedback and comments.",
                displayName: "Materials are attached",
            },
        ],
}
