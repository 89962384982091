import React, { useContext, useEffect, useState } from "react"
import { useFrontContext } from "./providers/frontContext"
import { SingleConversationContext } from "@frontapp/ui-bridge/dist/internal/contextTypesV2"
import { ApplicationMessage, ApplicationTag } from "@frontapp/plugin-sdk"
import PromptInput from "./PromptInput"
import { openApiGenerateResponse } from "./logic/gptFunctions"
import PredefinedPrompt from "./PredefinedPrompt"
import { EmptyState, Heading, Paragraph } from "@frontapp/ui-kit"
import { extractTextFromHtml } from "./logic/message"
import { generatePrompt, prompts } from "./logic/prompt"
import { useMutation } from "react-query"
import Spinner from "./spinner/Spinner"
import ApiKeyInput from "./ApiKeyInput"
import { ApiKeyContext } from "./providers/apiKeyContext"

function GenerateDraftResponse(): React.ReactElement {
    const context: SingleConversationContext = useFrontContext()
    const [latestMessage, setLatestMessage] = useState<ApplicationMessage>()
    const [tags, setTags] = useState<ApplicationTag[]>([])
    const { apiKey } = useContext(ApiKeyContext)
    const generateDraft = useMutation(
        async ({ prompt, body }: { prompt: string; body: string }) =>
            openApiGenerateResponse(generatePrompt(prompt, body), apiKey ?? ""),
        {
            onSuccess: (draft) => {
                createDraft(draft)
            },
        }
    )

    const tagPrompts = tags
        .map((tag) => prompts[tag.id])
        .filter((p) => p !== undefined)
        .reduce((el, acc) => [...el, ...acc], [])

    useEffect(() => {
        setTags([...context.conversation.tags])
        context.listMessages().then((response) => {
            if (response.results.length > 0) {
                const latestMessageIndex = response.results.length - 1
                setLatestMessage(response.results[latestMessageIndex])
            } else {
                setLatestMessage(undefined)
            }
        })
    }, [context])

    const createDraft = (responseText: string) => {
        if (!latestMessage) return

        context.createDraft({
            content: {
                body: responseText,
                type: "text",
            },
            replyOptions: {
                type: "replyAll",
                originalMessageId: latestMessage.id,
            },
        })
    }

    const onSubmit = (prompt: string) => {
        if (
            latestMessage === undefined ||
            latestMessage.content === undefined
        ) {
            return
        }
        let body = latestMessage.content.body
        if (latestMessage.content.type === "html") {
            body = extractTextFromHtml(body)
        }

        generateDraft.mutate({ prompt: prompt, body: body })
    }

    if (apiKey === null) {
        return (
            <div style={{ padding: 8 }}>
                <p>Enter OpenAI API key:</p>
                <ApiKeyInput
                    placeholder="OpenAI API key"
                    cookieName="open-ai-key"
                />
            </div>
        )
    }

    return (
        <div style={{ padding: 8 }}>
            {generateDraft.isLoading ? (
                <div style={{ paddingBottom: 24, textAlign: "center" }}>
                    <Spinner />
                    <Paragraph color="grey">
                        Getting response from ChatGPT...
                    </Paragraph>
                </div>
            ) : undefined}
            <div style={{ textAlign: "center" }}>
                <Heading>Predefined prompts</Heading>
            </div>
            {tagPrompts.map((prompt, idx) => (
                <PredefinedPrompt
                    prompt={prompt}
                    key={idx}
                    onClick={onSubmit}
                />
            ))}
            {tagPrompts.length === 0 ? (
                <div
                    style={{
                        textAlign: "center",
                        marginTop: 32,
                        marginBottom: 32,
                        height: 64,
                    }}
                >
                    <EmptyState message="No predefined prompts" />
                </div>
            ) : undefined}
            <div style={{ marginTop: 8, textAlign: "center" }}>
                <Heading>or</Heading>
            </div>

            <PromptInput onClick={onSubmit} messageId={latestMessage?.id} />
        </div>
    )
}

export default GenerateDraftResponse
