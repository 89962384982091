import React from "react"
import "./Spinner.css"

function Spinner(): React.ReactElement {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                margin: "4px 0",
            }}
        >
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Spinner
