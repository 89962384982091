import React, { createContext, useContext, useEffect, useState } from "react";
import Front from "@frontapp/plugin-sdk";

/*
 * Context.
 */

export const FrontContext = createContext(null);

/*
 * Hook.
 */

export function useFrontContext(): any {
  return useContext(FrontContext);
}

/*
 * Component.
 */

export const FrontContextProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [context, setContext] = useState<any>();

  useEffect(() => {
    const subscription = Front.contextUpdates.subscribe((frontContext) => {
      setContext(frontContext);
    });
    return () => subscription.unsubscribe();
  }, []);

  return (
    <FrontContext.Provider value={context}>{children}</FrontContext.Provider>
  );
};
