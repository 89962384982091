import React, { useContext, useState } from "react"
import { Button, Input, VisualSizesEnum } from "@frontapp/ui-kit"
import { ApiKeyContext } from "./providers/apiKeyContext"

interface Props {
    placeholder: string
    cookieName: string
}

function ApiKeyInput(props: Props): React.ReactElement {
    const [key, setKey] = useState("")
    const { setApiKey } = useContext(ApiKeyContext)

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <div style={{ marginTop: 8, width: "100%" }}>
                <Input
                    placeholder={props.placeholder}
                    onChange={setKey}
                    value={key}
                />
            </div>
            <div style={{ marginTop: 8 }}>
                <Button
                    size={VisualSizesEnum.SMALL}
                    onClick={() => {
                        setApiKey(key)
                    }}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default ApiKeyInput
