import React, { useState } from "react"

export interface ApiKeyContextProps {
    apiKey: string | null
    setApiKey: (apiKey: string | null) => void
}

const ApiKeyContext = React.createContext<ApiKeyContextProps>({
    apiKey: null,
    setApiKey: () => {},
})

const ApiKeyProvider = ({ children }: { children: React.ReactElement }) => {
    const [apiKey, setApiKey] = useState<string | null>(null)

    const contextValue: ApiKeyContextProps = {
        apiKey,
        setApiKey,
    }

    return (
        <ApiKeyContext.Provider value={contextValue}>
            {children}
        </ApiKeyContext.Provider>
    )
}

export { ApiKeyContext, ApiKeyProvider }
