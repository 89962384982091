import React from "react"
import { Task } from "@frontapp/ui-kit"
import { Prompt } from "./logic/prompt"

interface Props {
    prompt: Prompt
    onClick: (prompt: string) => void
}

function PredefinedPrompt(props: Props): React.ReactElement {
    return (
        <div style={{ marginTop: 8 }}>
            <Task
                label={props.prompt.displayName}
                onClick={() => props.onClick(props.prompt.prompt)}
            />
        </div>
    )
}

export default PredefinedPrompt
